import React from "react"
import { Box, ResponsiveContext } from "grommet"
import styled from "styled-components"

import Title from "./Typography/Title"
import { themeExtension } from "../styles/theme"
import { smLargeELSEMedium } from "../helpers/breakpoints"
import RoundedBottomBG from "../img/round-bottomBIG.svg"
import RoundedBottomBGWhite from "../img/round-bottomBIGWhite.svg"

const RoundedBottom = styled(Box)`
  z-index: 1;
  background: url(${props => props.transition}) bottom center no-repeat,
    ${props => props.background};
`

const Landing = ({
  subTitle,
  title,
  height = "medium",
  align = "center",
  desc = true,
  background = themeExtension.gradients.dark,
  variant = "light",
  transition = "gray",
  disableTitle = false,
}) => (
  <ResponsiveContext.Consumer>
    {size => (
      <RoundedBottom
        align="center"
        background={background}
        direction="column"
        transition={
          !!transition
            ? transition === "gray"
              ? RoundedBottomBG
              : transition === "white"
              ? RoundedBottomBGWhite
              : "none"
            : null
        }
        pad={{
          vertical: "xlarge",
          horizontal: "medium",
        }}
        style={{ position: "relative" }}
      >
        <Box
          direction="column"
          height={height}
          pad="medium"
          width="xxlarge"
          justify="center"
        >
          <Box
            id="spacer-navbar"
            pad={{ vertical: smLargeELSEMedium(size) }}
          ></Box>
          {disableTitle ? null : (
            <Title
              title={title}
              tlevel={1}
              subTitle={subTitle}
              align={align}
              desc={desc}
              variant={variant}
            />
          )}
        </Box>
      </RoundedBottom>
    )}
  </ResponsiveContext.Consumer>
)

export default Landing
