import React from "react"
import { Box } from "grommet"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Landing from "../components/Landing"
import SimpleAccordion from "./faq/Accordion"
import Wrapper from "../components/Container/Wrapper"
import { themeExtension } from "../styles/theme"
import FaqContact from "./faq/FaqContact"

const FAQ = ({ data }) => {
  const faqData =
    data && data.allMarkdownRemark.edges[0].node.frontmatter.faqitems
  return (
    <Layout background={themeExtension.colors.backgrounds.light}>
      <SEO title="Frequently Asked Questions" />
      <Landing
        title="Frequently Asked Questions"
        subTitle="Browse the questions below to find your answer, even if it's not there yet."
        transition="gray"
      />
      <Wrapper
        pad={{ top: "none", horizontal: "medium", bottom: "xlarge" }}
        background={themeExtension.colors.backgrounds.light}
        margin={{ top: "-100px" }}
      >
        <Box
          style={{
            boxShadow: themeExtension.shadows.graySoft,
            zIndex: 2,
          }}
          round="small"
          pad={{ bottom: "large", horizontal: "large", top: "medium" }}
          background="white"
          animation="slideLeft"
        >
          <SimpleAccordion data={faqData} />
        </Box>
      </Wrapper>
      <FaqContact />
    </Layout>
  )
}

export const query = graphql`
  query GettingFaqItems {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          faqitems: { elemMatch: { identification: { eq: "Faq" } } }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            faqitems {
              body
              identification
              title
            }
          }
        }
      }
    }
  }
`

export default FAQ
