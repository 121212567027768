import React, { useState } from "react"

import { Accordion, AccordionPanel, Box, Markdown, Paragraph } from "grommet"
import { FormDown, FormUp } from "grommet-icons"

const renderPanelHeader = (title, active) => (
  <Box
    direction="row"
    align="center"
    pad={{ vertical: "medium" }}
    justify="between"
  >
    <Paragraph
      margin={{ vertical: "small", horizontal: "none", right: "small" }}
      color="dark-3"
      size="large"
      style={{ fontWeight: 500, maxWidth: "100%" }}
    >
      <Markdown>{title}</Markdown>
    </Paragraph>
    {active ? <FormDown /> : <FormUp />}
  </Box>
)

const SimpleAccordion = ({ animate, data = [], multiple, ...rest }) => {
  const [activeIndex, setActiveIndex] = useState([0])

  return (
    <Box {...rest}>
      <Accordion
        animate={animate}
        activeIndex={activeIndex}
        onActive={newActiveIndex => setActiveIndex(newActiveIndex)}
      >
        {data.map((faqItem, i) => {
          const body = faqItem.body
          const title = faqItem.title
          return (
            <AccordionPanel
              header={renderPanelHeader(title, activeIndex.includes(i))}
              key={i}
            >
              <Box background="light-2" overflow="auto" pad="medium">
                <Markdown
                  components={{
                    p: {
                      component: "p",
                      props: { size: "xlarge" },
                    },
                  }}
                >
                  {body}
                </Markdown>
              </Box>
            </AccordionPanel>
          )
        })}
      </Accordion>
    </Box>
  )
}
export default SimpleAccordion
