import React from "react"
import { Box } from "grommet"
import { graphql, useStaticQuery } from "gatsby"

import { themeExtension } from "../../styles/theme"
import Title from "../../components/Typography/Title"
import Wrapper from "../../components/Container/Wrapper"
import Contact from "./Contact"

const FaqContact = ({ size }) => {
  const { heading_subtitle, heading } = useContactFormData()
  return (
    <Wrapper background={themeExtension.gradients.dark}>
      <Box
        direction="row"
        width="xxlarge"
        justify="center"
        align="center"
        wrap={size === "small" ? true : false}
      >
        <Box
          basis={size === "small" ? "fill" : "3/4"}
          pad={size === "small" ? { bottom: "large" } : { right: "xlarge" }}
        >
          <Title
            title={heading}
            paddingBottom="none"
            tlevel={2}
            variant="light"
            subTitle={heading_subtitle}
            align={size === "small" ? "center" : "start"}
            desc
          />
        </Box>
        <Contact />
      </Box>
    </Wrapper>
  )
}

export const useContactFormData = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query contactFormFaq {
        allMdx(
          filter: { frontmatter: { identification: { eq: "faqContactForm" } } }
        ) {
          edges {
            node {
              frontmatter {
                heading_subtitle
                heading
              }
            }
          }
        }
      }
    `
  )
  console.log(allMdx)
  return allMdx.edges[0].node.frontmatter
}

export default FaqContact
