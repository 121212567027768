import React from "react"
import { Box, Heading } from "grommet"

const Title = ({
  title,
  subTitle,
  variant,
  paddingBottom = "medium",
  align = "start",
  desc = false,
  tlevel = 2,
  width,
  ...rest
}) => {
  const titleColor = variant === "light" ? "light-1" : "#4F4F4F"
  const subTitleColor = variant === "light" ? "#F2F2F2" : "dark-3"
  return (
    <Box pad={{ bottom: paddingBottom }}>
      {desc ? null : (
        <Heading
          level={3}
          color={subTitleColor}
          alignSelf={align}
          textAlign={align}
          margin={{ vertical: "xsmall" }}
          style={{ maxWidth: "100%", fontWeight: "600" }}
        >
          {subTitle}
        </Heading>
      )}
      <Heading
        level={tlevel}
        textAlign={align}
        alignSelf={align}
        color={titleColor}
        margin={{
          vertical: "xsmall",
          horizontal: "0",
        }}
      >
        {title}
      </Heading>
      {!desc ? null : (
        <Heading
          level={3}
          color={subTitleColor}
          alignSelf={align}
          textAlign={align}
          margin={{ top: "xsmall", bottom: "small" }}
          style={{ maxWidth: "100%", fontWeight: "600" }}
        >
          {subTitle}
        </Heading>
      )}
    </Box>
  )
}

export default Title
