import React from "react"
import { Box, Paragraph, Layer, Heading, ResponsiveContext } from "grommet"

const SmallDialog = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Layer
          position="bottom"
          modal={false}
          margin={{ vertical: "large", horizontal: "none" }}
          //   onEsc={() => setDialog(false)}
          responsive={false}
          plain
          style={size === "small" ? { width: "80%" } : {}}
        >
          <Box
            align="center"
            direction="column"
            gap="small"
            justify="between"
            round="small"
            elevation="medium"
            pad="medium"
            background="brand"
          >
            <Heading level="3" margin="xsmall" textAlign="center">
              Thank you for getting in touch!
            </Heading>
            <Paragraph textAlign="center" margin="xsmall">
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!
            </Paragraph>
            <Paragraph margin="xsmall">Have a great day!</Paragraph>
          </Box>
        </Layer>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default SmallDialog
